exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2018-hackergame-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/2018-hackergame.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2018-hackergame-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2018-steam-summer-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/2018-steam-summer.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2018-steam-summer-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2019-ustc-hackergame-writeup-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/2019-ustc-hackergame-writeup.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-2019-ustc-hackergame-writeup-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-a-great-software-wgstures-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/a-great-software-wgstures.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-a-great-software-wgstures-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-authlib-usage-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/authlib-usage.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-authlib-usage-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-automatic-deploy-rancher-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/automatic-deploy-rancher.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-automatic-deploy-rancher-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-build-python-on-centos-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/build-python-on-centos.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-build-python-on-centos-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-curseforge-minecraft-modpack-format-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/curseforge-minecraft-modpack-format.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-curseforge-minecraft-modpack-format-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-deploy-mtproxu-in-linux-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/deploy-mtproxu-in-linux.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-deploy-mtproxu-in-linux-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-diy-gnuk-token-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/diy-gnuk-token.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-diy-gnuk-token-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-dubbo-with-kotlin-in-spring-boot-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/dubbo-with-kotlin-in-spring-boot.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-dubbo-with-kotlin-in-spring-boot-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-grafana-collectd-collectd-monitor-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/grafana-collectd-collectd-monitor.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-grafana-collectd-collectd-monitor-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-happy-mid-autumn-festival-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/happy-mid-autumn-festival.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-happy-mid-autumn-festival-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-happy-new-year-md-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/happy-new-year-md.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-happy-new-year-md-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-hello-study-year-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/hello-study-year.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-hello-study-year-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-hello-typecho-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/hello-typecho.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-hello-typecho-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-home-40-g-networking-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/home-40g-networking.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-home-40-g-networking-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-install-mod-in-l-4-d-2-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-install-mod-in-l4d2.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-install-mod-in-l-4-d-2-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-install-tsunamiudp-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-install-tsunamiudp.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-install-tsunamiudp-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-run-left-4-dead-2-server-in-linux-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-run-left-4-dead-2-server-in-linux.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-run-left-4-dead-2-server-in-linux-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-extra-disk-in-vm-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-extra-disk-in-vm.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-extra-disk-in-vm-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-mulitmc-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-mulitmc.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-mulitmc-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-openvpn-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-openvpn.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-openvpn-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-proxychains-to-speedup-network-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-proxychains-to-speedup-network.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-proxychains-to-speedup-network-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-rancher-to-manage-docker-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-rancher-to-manage-docker.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-rancher-to-manage-docker-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-saltstack-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-saltstack.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-saltstack-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-telegram-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/how-to-use-telegram.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-how-to-use-telegram-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-centos-via-network-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/install-centos-via-network.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-centos-via-network-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-linux-in-xiaoxin-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/install-linux-in-xiaoxin.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-linux-in-xiaoxin-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-pingfang-font-in-windwows-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/install-pingfang-font-in-windwows.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-pingfang-font-in-windwows-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-wsl-2-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/install-wsl2.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-install-wsl-2-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-k-2-t-power-modify-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/k2t-power-modify.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-k-2-t-power-modify-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-left-4-dead-2-rsync-source-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/left4dead2-rsync-source.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-left-4-dead-2-rsync-source-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-ma-5671-a-flash-and-use-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/ma5671a-flash-and-use.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-ma-5671-a-flash-and-use-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-mount-oss-in-aliyun-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/mount-oss-in-aliyun.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-mount-oss-in-aliyun-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-netlify-cms-in-blog-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/netlify-cms-in-blog.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-netlify-cms-in-blog-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-nodejs-base-64-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/nodejs-base64.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-nodejs-base-64-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-openvpn-auth-system-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/openvpn-auth-system.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-openvpn-auth-system-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-python-clean-screen-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/python-clean-screen.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-python-clean-screen-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-rancher-share-volume-in-different-hosts-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/rancher-share-volume-in-different-hosts.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-rancher-share-volume-in-different-hosts-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-recv-and-send-sms-in-raspberry-pi-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/recv-and-send-sms-in-raspberry-pi.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-recv-and-send-sms-in-raspberry-pi-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-run-sspanel-uim-with-docker-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/run-sspanel-uim-with-docker.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-run-sspanel-uim-with-docker-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-soyoustart-arm-to-archlinux-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/soyoustart-arm-to-archlinux.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-soyoustart-arm-to-archlinux-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-start-typecho-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/start-typecho.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-start-typecho-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-starting-using-k-3-s-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/starting-using-k3s.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-starting-using-k-3-s-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-startup-in-j-1900-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/startup-in-j1900.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-startup-in-j-1900-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-switch-rancher-to-swarm-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/switch-rancher-to-swarm.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-switch-rancher-to-swarm-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-telegram-package-tracker-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/telegram-package-tracker.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-telegram-package-tracker-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-transfer-typecho-to-jekyll-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/transfer-typecho-to-jekyll.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-transfer-typecho-to-jekyll-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-upgrade-git-on-centos-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/upgrade-git-on-centos.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-upgrade-git-on-centos-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-upload-image-to-smms-when-use-trix-editor-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/upload-image-to-smms-when-use-trix-editor.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-upload-image-to-smms-when-use-trix-editor-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-asf-2-to-farm-in-linux-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/use-asf2-to-farm-in-linux.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-asf-2-to-farm-in-linux-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-dns-over-tls-in-golang-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/use-dns-over-tls-in-golang.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-dns-over-tls-in-golang-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-redsocks-proxy-global-via-redsocks-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/use-redsocks-proxy-global-via-redsocks.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-redsocks-proxy-global-via-redsocks-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-socat-to-speed-up-tcp-link-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/use-socat-to-speed-up-tcp-link.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-use-socat-to-speed-up-tcp-link-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-welcome-to-ghost-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/welcome-to-ghost.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-welcome-to-ghost-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-windows-package-manager-scoop-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/windows-package-manager-scoop.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-windows-package-manager-scoop-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-xiaomi-miflash-error-md": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/blog/xiaomi-miflash-error.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-builds-indexyz-blog-gastby-content-blog-xiaomi-miflash-error-md" */),
  "component---src-templates-normal-page-tsx-content-file-path-builds-indexyz-blog-gastby-content-pages-links-md": () => import("./../../../src/templates/NormalPage.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/pages/links.md" /* webpackChunkName: "component---src-templates-normal-page-tsx-content-file-path-builds-indexyz-blog-gastby-content-pages-links-md" */),
  "component---src-templates-normal-page-tsx-content-file-path-builds-indexyz-blog-gastby-content-pages-wallet-md": () => import("./../../../src/templates/NormalPage.tsx?__contentFilePath=/builds/Indexyz/Blog/gastby/content/pages/wallet.md" /* webpackChunkName: "component---src-templates-normal-page-tsx-content-file-path-builds-indexyz-blog-gastby-content-pages-wallet-md" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/PostList.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-tag-list-tsx": () => import("./../../../src/templates/TagList.tsx" /* webpackChunkName: "component---src-templates-tag-list-tsx" */)
}

